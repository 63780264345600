import { ProductType, useAppDispatch, useAppSelector } from '@idcms/store';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import Image from "next/legacy/image";
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { getTheme } from '../layout/Theme';
import { addToWishlist, removeFromWishlist, selectWishlist } from '../store/wishlistSlice';
import DynamicQuickAddToCart from './DynamicQuickAddToCart';
import { checkIfDiscountApplicable } from './checkIfDiscountApplicable';

type Props = {
    product: ProductType;
    id?: number;
}

const ProductCard = ({ product, id }: Props) => {
    const image = product.medium && product.medium.length > 0 ? product.medium[0] : null;
    const { t } = useTranslation('product')
    const wishlist = useAppSelector(selectWishlist);
    const dispatch = useAppDispatch();

    const discountApplicable = checkIfDiscountApplicable(product)

    const router = useRouter()
    const path = router.asPath.replace(router.asPath.substring(router.asPath.indexOf('?'), router.asPath.length + 1), `/${product.slug?.slug}`)
    const slug = router.pathname.includes('ortopedijos-gaminiai') ? (router.asPath.indexOf('?') === -1 ? `${router.asPath}/${product.slug?.slug}` : path) : `/ortopedijos-gaminiai/${product.slug?.slug}`
    const [showDialog, setShowDialog] = useState(false)
    const toggleQuickAddToCart = () => {
        setShowDialog(!showDialog)
    }
    const likedItem =
        wishlist?.products.find((o: ProductType) => o.id === product?.id) !== undefined;

    const handleLike = () => {
        if (likedItem) {
            dispatch(removeFromWishlist(product));
        }
        else {
            dispatch(addToWishlist(product));
        }
    }
    const theme = getTheme()

    const hasAvailableVariants = !!product.variants.find((v) => v.qty > 0)

    return (
        <>
            <Stack justifyContent={'space-between'}
                sx={{ backgroundColor: '#fff', boxShadow: '0px 3px 10px #19235726', borderRadius: 2 }}
                position={'relative'} height={'100%'}>
                <>
                    <Stack sx={{ position: 'absolute', m: 2, zIndex: 2 }}>
                        {likedItem && <FavoriteIcon onClick={handleLike} sx={{ fontSize: 30, color: theme.palette.primary.main, cursor: 'pointer' }} />}
                        {!likedItem && <FavoriteBorderIcon onClick={handleLike} sx={{ fontSize: 30, color: theme.palette.primary.main, cursor: 'pointer' }} />}
                    </Stack>
                    {discountApplicable && product.discountPercent > 0 &&
                        <Stack flexDirection={'row'} justifyContent={'flex-end'} >
                            <Typography position={'absolute'} zIndex={9} fontWeight={500}
                                sx={{
                                    color: '#fff', height: { lg: 34, md: 34, sm: 30, xs: 30 }, backgroundColor: 'warning.main',
                                    borderRadius: 2, borderStartStartRadius: 0, borderEndEndRadius: 0
                                }} py={.8} px={{ lg: 3, md: 3, sm: 2, xs: 2 }} m={0}>
                                {`-${product.discountPercent}%`}
                            </Typography>
                        </Stack>}
                    {(!product.discountPercent && product.isNew) ?
                        <Stack flexDirection={'row'} justifyContent={'flex-end'}>
                            <Typography position={'absolute'} zIndex={9} fontWeight={500}
                                sx={{
                                    color: '#fff', height: { lg: 34, md: 34, sm: 30, xs: 30 }, backgroundColor: 'warning.main',
                                    borderRadius: 2, borderStartStartRadius: 0, borderEndEndRadius: 0
                                }}
                                py={.5} px={{ lg: 3, md: 3, sm: 2, xs: 2 }} m={0}>
                                {t('isNew')}
                            </Typography>
                        </Stack> : ''}
                </>
                <Stack mx={2} pt={2} height={'100%'}>
                    <Link className='removeLinkUnderline' href={slug} passHref aria-label={product.translation?.title}>
                        <Stack
                            height={250}
                            sx={{ width: '100%', cursor: 'pointer', ":hover": { opacity: .9 }, position: 'relative' }}>
                            {!(hasAvailableVariants || product.qty > 0) &&
                                <Typography color={'warning.main'} width={'100%'} sx={{ position: 'relative', zIndex: 9, top: 120 }}
                                    fontWeight={600} variant='h6' textAlign={'center'} lineHeight={1.1}>
                                    {t('soldOut')}
                                </Typography>}
                            <Image
                                style={{ opacity: (hasAvailableVariants || product.qty > 0) ? 1 : .6, }}
                                priority
                                objectFit='cover'
                                layout='fill'
                                alt={product.translation?.title ?? ''}
                                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                                src={image?.media.md?.path ?? "/assets/images/fallback-img.jpg"}>
                            </Image>
                        </Stack>
                    </Link>

                    <Stack alignContent={'space-between'} height={'100%'} width={'100%'}>
                        <Stack display={'flex'} flexGrow={1} justifyContent={'center'} width={'100%'}>
                            <Typography color={'primary.main'} width={'100%'}
                                fontWeight={400} textAlign={'center'} pt={1} lineHeight={1.1}>
                                {product.translation?.title ?? ""}
                            </Typography>
                        </Stack>
                        <Typography component={'p'} fontWeight={'bold'} variant='h6'
                            color={(discountApplicable && product.price !== product.originalPrice) ? 'secondary.main' : 'primary.main'}
                            textAlign={'center'} pt={1} pb={1}>
                            {(discountApplicable ? product.price : product.originalPrice).toFixed(2)} Eur
                            {discountApplicable && product.price !== product.originalPrice &&
                                <Typography component={'span'} fontWeight={400} color={'#192357'} variant='subtitle1' py={1} ml={2} className='lineThrough'>
                                    &nbsp;{product.originalPrice.toFixed(2)} Eur&nbsp;
                                </Typography>}
                        </Typography>
                    </Stack>
                </Stack>
                {(hasAvailableVariants || product.qty > 0) ?
                    <Stack>
                        <Button variant={'contained'} id={id?.toString()} onClick={toggleQuickAddToCart}
                            sx={{
                                width: '100%',
                                margin: 0, height: 50,
                                fontSize: 18, borderRadius: 2,
                                borderStartEndRadius: 0, borderStartStartRadius: 0
                            }}>
                            {t('addToCart')}
                        </Button>
                    </Stack>

                    :
                    <Stack>
                        <Link href={slug} passHref>
                            <Button variant={'contained'} id={id?.toString()} color='secondary'
                                sx={{
                                    width: '100%', color: '#fff',
                                    margin: 0, height: 50,
                                    fontSize: 18, borderRadius: 2,
                                    borderStartEndRadius: 0, borderStartStartRadius: 0
                                }}>
                                {t('view')}
                            </Button>
                        </Link>
                    </Stack>

                }
            </Stack >
            <DynamicQuickAddToCart product={product} toggleQuickAddToCart={toggleQuickAddToCart} openQuickAddToCart={showDialog} />
        </>
    )
}

export default ProductCard