import { ProductType, formatDate } from "@idcms/store"

export const checkIfDiscountApplicable = (product: ProductType) => {
    const discountDateFrom = product.discountDateFrom ? new Date(product.discountDateFrom) : null
    const discountDateTo = product.discountDateTo ? new Date(product.discountDateTo) : null
    const dateNow = new Date()

    if (discountDateFrom && !discountDateTo) {
        return true
    }
    else if (discountDateFrom && discountDateTo) {
        return formatDate(dateNow) >= formatDate(discountDateFrom) && formatDate(dateNow) <= formatDate(discountDateTo)
    } else {
        return false
    }
}