import { PostType } from '@idcms/store'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'next-i18next'
import Image from "next/legacy/image"
import Link from 'next/link'
import CustomArrowButton from '../shared/CustomArrowButton'

type Props = {
    post?: PostType;
    productsView?: boolean;
}

const BlogPostCard = ({ post, productsView }: Props) => {
    const { t } = useTranslation('home')

    return (
        <Stack mt={2} mb={1} px={productsView ? 2 : 0} mx={{ lg: 0, md: 0, sm: 0, xs: 2 }}
            sx={{
                height: '100%',
                backgroundColor: productsView ? 'none' : '#fff', boxShadow: productsView ? 'none' : '0px 3px 8px #19235726',
                borderRadius: productsView ? '0' : 2, overflow: 'hidden', justifyContent: 'space-between'
            }}>
            <Stack>
                <Stack position={'relative'} width={'100%'} height={'100%'} maxHeight={'200px'} >
                    <Image
                        objectFit='cover'
                        layout='responsive'
                        width={100}
                        height={100}
                        alt=""
                        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                        src={post?.media.lg?.path ?? "/assets/images/fallback-img.jpg"}>
                    </Image>
                </Stack>
                <Stack px={productsView ? 0 : 3} pt={2.5} pb={0} >
                    <Typography component={'h3'}
                        textTransform={'uppercase'} fontWeight={600} variant='subtitle1' lineHeight={1.2}
                        mb={.5} color={'primary'} textAlign={productsView ? 'left' : 'center'}>
                        {post?.translation?.title}
                    </Typography>

                    <Typography fontWeight={300} variant='body2' className='maxLines'
                        mt={1} color={'primary'} pb={1.5} textAlign={'justify'}>
                        {post?.translation?.shortContent}
                    </Typography>
                </Stack>
            </Stack>
            <Stack width={'140px'} pb={1} mt={3} mx={productsView ? 0 : 3}>
                <Link passHref href={`/informacija/naudinga-zinoti/${post?.translation?.slug}`} className='removeLinkUnderline'>
                    <CustomArrowButton text={t('shared.view')} color={'#F8F8F8'} />
                </Link>
            </Stack>
        </Stack>
    )
}

export default BlogPostCard