import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography'
import Image from 'next/image'

type Props = {
    text: string;
    onClick?: () => void;
    color?: string;
}

const CustomArrowButton = ({ text, onClick, color }: Props) => {
    return (
        <Button variant='contained' color='info' fullWidth onClick={onClick}
            sx={{
                height: '28px', display: 'flex', backgroundColor: color ? color : '#fff',
                flexDirection: 'row', justifyContent: 'center', marginBottom: 2, paddingY: 2
            }}>
            <Typography py={0} m={0} color={'primary.dark'}
                fontWeight={400} fontSize={'14px'} variant={'button'} textTransform={'uppercase'}>
                {text}
            </Typography>
            <Stack mt={0} ml={1}>
                <Image
                    width={5.436}
                    height={10.659}
                    priority
                    src={'/assets/icons/arrow-small.svg'}
                    alt="arrow"
                />
            </Stack>
        </Button>
    )
}

export default CustomArrowButton